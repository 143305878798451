<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("options.title")
        }}</v-toolbar-title>
        <v-spacer />
        <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
          <v-avatar left>
            <v-icon small>mdi-alert</v-icon>
          </v-avatar>
          {{ $t("general.readonly") }}
        </v-chip>
      </v-toolbar>

      <v-card-text class="overflow-y-auto pa-5" :style="viewHeight()">
        <v-form ref="form" v-model="valid">
          <collapsable-card :title="$t('options.authTokens')">
            <v-btn small depressed tile class="my-1" @click="addToken"
              ><v-icon>mdi-plus</v-icon> Add New Token</v-btn
            >
            <v-divider />
            <v-list>
              <v-list-item v-for="(t, i) in authTokens" :key="i">
                <v-list-item-content class="d-flex">
                  <v-chip label>{{ t }}</v-chip>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon color="red" @click="deleteToken(t)"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list>

            
            <v-divider />
            <br />

          <h3>API Documentation: Get Remote Data</h3>
          <p>
            This API endpoint retrieves remote data related to incidents based
            on specified parameters. It returns a list of
            <code>IncidentInfo</code> objects.
          </p>

          <h3>Endpoint:</h3>
          <ul>
            <li><strong>Method:</strong> GET</li>
            <li>
              <strong>URL:</strong>
              https://dss.immap.site/api/v1/si/data/{token}
            </li>
          </ul>

          <br />

          <h3>Parameters:</h3>
          <ol>
            <li>
              <strong>Path Parameter:</strong>
              <ul>
                <li>
                  <code>{token}</code> (String): A unique identifier token for
                  accessing the remote data.
                </li>
              </ul>
            </li>
            <li>
              <strong>Query Parameters:</strong>
              <ul>
                <li>
                  <code>search</code> (String, optional, default: ""): A search
                  query string to filter incidents.
                </li>
                <li>
                  <code>incidentType</code> (Integer, optional, default: -1):
                  The type of incident. Defaults to -1 if not provided.
                </li>
                <li>
                  <code>area</code> (Integer, optional, default: -1): The area
                  code associated with the incidents. Defaults to -1 if not
                  provided.
                </li>
                <li>
                  <code>gcaNgca</code> (Integer, optional, default: -1): The GCA
                  (Ground Controlled Approach) or NGCA (Non-Ground Controlled
                  Approach) code. Defaults to -1 if not provided.
                </li>
                <li>
                  <code>shiftSign</code> (Integer, optional, default: -1): The
                  shift sign code associated with the incidents. Defaults to -1
                  if not provided.
                </li>
                <li>
                  <code>from</code> (String, optional, default: ""): The
                  starting date or timestamp from which incidents should be
                  considered.
                </li>
                <li>
                  <code>to</code> (String, optional, default: ""): The ending
                  date or timestamp until which incidents should be considered.
                </li>
              </ul>
            </li>
          </ol>

          <br />

          <h3>Response:</h3>
          <ul>
            <li>
              <strong>Type:</strong>
              IncidentInfo[]
            </li>
            <li>
              <strong>Description:</strong> A list of
              <code>IncidentInfo</code> objects containing data related to the
              incidents.
            </li>
            <li>
              <strong>Status Codes:</strong>
              <ul>
                <li>200: Successful retrieval of data.</li>
                <li>400: Bad request (invalid parameters, etc.).</li>
                <li>403: Forbidden (invalid authorization token, etc.).</li>
                <li>500: Internal server error.</li>
              </ul>
            </li>
          </ul>

          <br />

          <h3>Example Usage:</h3>
          <pre>
    <code>GET https://dss.immap.site/api/v1/si/data/auth_token?search=query&amp;incidentType=1&amp;area=2&amp;gcaNgca=0&amp;shiftSign=1&amp;from=2024-01-01&amp;to=2024-02-01</code>
  </pre>

          <br />

          <h3>Notes:</h3>
          <ul>
            <li>
              The <code>search</code>, <code>from</code>, and
              <code>to</code> parameters support string-based filtering or date
              range filtering for incidents.
            </li>
            <li>
              Other parameters (<code>incidentType</code>, <code>area</code>,
              <code>gcaNgca</code>, <code>shiftSign</code>) provide
              integer-based filtering options.
            </li>
            <li>All parameters are optional with default values provided.</li>
            <li>
              Ensure to replace <code>auth_token</code> with a valid token for
              accessing the remote data.
            </li>
          </ul>

          
          <br />

          </collapsable-card>

          <!-- <collapsable-card :title="$t('route.alerts')">
            <v-checkbox
              class="ma-0"
              dense
              :readonly="isReadOnly"
              v-model="item.sendAlertOnNewReport"
              :label="$t('notifications.sendAlertOnNewReport')"
            ></v-checkbox>
            <v-checkbox
              class="ma-0"
              dense
              :readonly="isReadOnly"
              v-model="item.sendAlertOnReportUpdate"
              :label="$t('notifications.sendAlertOnReportUpdate')"
            ></v-checkbox>
            <v-checkbox
              class="ma-0"
              dense
              :readonly="isReadOnly"
              v-model="item.sendAlertOnReportDeleteRequest"
              :label="$t('notifications.sendAlertOnReportDeleteRequest')"
            ></v-checkbox>
            <v-checkbox
              v-model="item.sendAlertsByEmail"
              class="ma-0"
              dense
              :readonly="isReadOnly"
              :label="$t('notifications.sendAlertsByEmail')"
            ></v-checkbox>
            <v-textarea
              v-model="authTokens"
              rows="2"
              dense
              :readonly="isReadOnly"
              :label="$t('notifications.authTokens')"
            >
            </v-textarea>
          </collapsable-card> -->

          <v-divider />
          <v-btn
            :disabled="isReadOnly || !valid"
            :dark="!isReadOnly && valid"
            color="success"
            class="mr-4"
            tile
            depressed
            @click="save"
          >
            <v-icon>mdi-content-save</v-icon>
            {{ $t("general.save") }}
          </v-btn>

          <v-btn
            :disabled="isReadOnly"
            color="primary"
            tile
            depressed
            @click="reset"
          >
            <v-icon>mdi-undo-variant</v-icon>
            {{ $t("general.undo") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  LOAD_NOTIFICATION_OPTIONS,
  GET_NOTIFICATION_OPTIONS,
  SAVE_NOTIFICATION_OPTIONS,
  SEND_BULK_EMAILS,
} from "@/store/actions/type.options";
import CollapsableCard from "@/common/components/CollapsableCard";

export default {
  name: "Options",
  components: { CollapsableCard },
  data() {
    return {
      tab: {},
      compose: { subject: null, message: null, to: [] },
      users: [],
      orgs: [],
      filter: {
        orgId: null,
        users: [],
      },
      valid: false,
      authTokens: [],
      originalState: {},
      item: {
        weeklyUpdatesDay: "SUN",
        weeklyUpdatesHour: 12,
        weeklyUpdatesMinute: 0,
        inactiveUsersNotificationDays: 14,
        sendAlertOnNewSubAgreement: false,
        sendAlertOnSubAgreementUpdate: false,
      },
      weekDays: [
        { name: this.$t("weekday.sun"), value: "SUN" },
        { name: this.$t("weekday.mon"), value: "MON" },
        { name: this.$t("weekday.tue"), value: "TUE" },
        { name: this.$t("weekday.wed"), value: "WED" },
        { name: this.$t("weekday.thu"), value: "THU" },
        { name: this.$t("weekday.fri"), value: "FRI" },
        { name: this.$t("weekday.sat"), value: "SAT" },
      ],
    };
  },
  async mounted() {
    this[LOAD_NOTIFICATION_OPTIONS]().then((data) => {
      if (data) {
        this.item = data;
        this.authTokens = this.item.authTokens || [];
        this.originalState = { ...data };
      }
    });

    this.users = await this.loadUsers({ orgId: -1 });
    this.orgs = await this.loadOrganizations();
  },
  computed: {
    ...mapGetters("options", [GET_NOTIFICATION_OPTIONS]),

    changed() {
      return !this.compareObjects(this.item, this.originalState);
    },

    getUsersOfSelectedOrg() {
      return this.users.filter((f) => f.organizationId === this.filter.orgId);
    },
  },
  methods: {
    ...mapActions("options", [
      LOAD_NOTIFICATION_OPTIONS,
      SAVE_NOTIFICATION_OPTIONS,
      SEND_BULK_EMAILS,
    ]),
    ...mapActions("organizations", ["loadOrganizations"]),
    ...mapActions("users", ["loadUsers"]),

    deleteToken(t) {
      this.authTokens.splice(this.authTokens.indexOf(t), 1);
    },
    addToken() {
      const token = this.generateGuid();
      this.authTokens.push(token);
    },

    save() {
      this.item.authTokens = this.authTokens || [];
      this[SAVE_NOTIFICATION_OPTIONS](this.item);
    },
    removeFrom(item) {
      const coll = this.filter.users;
      coll.splice(coll.indexOf(item), 1);
      this.filter.users = [...coll];
    },
    send() {
      const userIds = this.filter.users.map((m) => m.id);
      this[SEND_BULK_EMAILS]({
        to: userIds,
        subject: this.compose.subject,
        body: this.compose.body,
      });
    },
    reset() {
      this.item = { ...this.originalState };
    },
  },
};
</script>
